import styled from 'styled-components'
import { breakpoint, SectionAlign } from '../../layout/mixins'
import CustomLink from '../CustomLink'
import LogoAsset from '../../layout/assets/logotype.svg'

export const Section = styled(SectionAlign)`
  padding-top: ${({ theme }) => theme.gutterXLarge};
  padding-bottom: ${({ theme }) => theme.gutterXLarge};
`

export const Grid = styled.footer`
  display: flex;
  flex-wrap: wrap;
`

export const GridItem = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.gutter};

  &:last-child {
    padding-bottom: 0;
    
    ${breakpoint.up('phone')`
      text-align: right;
    `}
  }

  ${breakpoint.up('phone')`
    width: 25%;
    padding-bottom: 0;
  `}
`


export const List = styled.ul``

export const Item = styled.li`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  padding-bottom: 0.5em;
  
  ${breakpoint.down('phone')`
    font-size: ${({ theme }) => theme.fontsizeSmall};
  `}
`

export const Link = styled(CustomLink)`
  text-decoration: none;
`
export const Logo = styled(LogoAsset)`
  width: 45px;
  fill: ${({ theme }) => theme.colorAccent};
  margin-right: 7px; /* Magic number to align logotype with header logotype */
`