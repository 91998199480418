export const blockData = (blocks, blockName) => blocks
  .filter((block) => block['__typename'] === blockName)[0]

export const trimUnits = (value) => parseInt(value.match(/(\d+(\.\d+)?)/)[0], 10)

export const isExternalUrl = (url) => {
  const pattern = /^((http|https):\/\/)|^((mailto|tel):)|^(www.)/
  return pattern.test(url)
}

export const isHashUrl = (url) => url.includes('#')


export const isEmail = (email) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}

export const outsideClick = (targets, callback) => {
  const handler = (evt) => {
    const insideElements = Array.from(document.querySelectorAll(targets))
    let targetElement = evt.target // clicked element

    do {
      if (insideElements.includes(targetElement)) {
        // Click inside.
        // Do nothing, just return.
        return
      }
      // Go up the DOM.
      targetElement = targetElement.parentNode
    } while (targetElement)

    // Clicked outside. Do something useful here.
    if (typeof callback === 'function') {
      callback()
    }
  }

  const listen = () => {
    document.addEventListener('click', handler)
  }

  const remove = () => {
    document.removeEventListener('click', handler)
  }

  return {
    listen,
    remove
  }
}


export const encodeObject = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&')

export const isSSR = typeof window === 'undefined'
