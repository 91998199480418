import styled from 'styled-components'
import { Link } from 'gatsby'
import { breakpoint, Heading1Styles, SectionAlign } from '../../layout/mixins'
import LogoAsset from '../../layout/assets/logotype.svg'


export const Section = styled.header`
  position: absolute;
  top: calc(${({ theme }) => theme.gutter} - ${({ theme }) => theme.gutterSmall});
  width: 100%;
  z-index: ${({ theme }) => theme.zLevel3};

  body.has-scrolled & {
    top: 0;
    position: fixed;
    background: ${({ theme }) => theme.backgroundColorAlt};
  }
`
export const Align = styled(SectionAlign)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  height: 70px;

  ${breakpoint.up('phone')`
    align-items: center;
  `}

  body.has-scrolled & {
    height: 65px;
    align-items: center;
  }
`

export const Logo = styled(LogoAsset)`
  width: 60px;
  height: 53px;
  transition: fill ${({ theme }) => theme.transitionRegular};
  fill: #fff;

  body.has-scrolled & {
    fill: ${({ theme }) => theme.colorAccent};
  }
`

export const LogoLink = styled(Link)`
  ${breakpoint.up('phone')`
    transition: transform ${({ theme }) => theme.transitionRegular};
  `}

  body.has-scrolled & {
    transform: scale(0.75);
  }
`
export const HeadingLink = styled(Link)`
  text-decoration: none;
`


export const Heading = styled.h1`
  ${Heading1Styles}
  font-size: 1.75rem; /* Magic number */
  color: ${({ theme }) => theme.textcolorSecondary};
  max-width: 80%;

  ${breakpoint.up('phone')`
    max-width: none;
    transition: color ${({ theme }) => theme.transitionRegular}, font-size ${({ theme }) => theme.transitionRegular};
  `}

  body.has-scrolled & {
    color: ${({ theme }) => theme.colorAccent};
    font-size: ${({ theme }) => theme.fontsizeSmall};

    ${breakpoint.up('phone')`
      font-size: ${({ theme }) => theme.heading3FontSize};
    `}
  }
`