import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Grid,
  GridItem,
  Section,
  List,
  Item,
  Link,
  Logo
} from './styles'

const footerQuery = graphql`
  query FooterQuery {
    data: datoCmsGeneral {
      contactEmail
      footerLinks {
        title
        url
      }
    }
  }
`

const Footer = () => {
  const {
    data: {
      contactEmail,
      footerLinks
    }
  } = useStaticQuery(footerQuery)

  const year = new Date().getFullYear()
  const [linksColumn1, linksColumn2] = [footerLinks.slice(0, 3), footerLinks.slice(3)]

  return (
    <Section data-cy="footer">
      <Grid>
        <GridItem>
          <List>
            <Item>
              {' '}
              Copyright &copy; Bengt Dahlgren
              {' '}
              {year}
            </Item>
            <Item>
              <Link to={`mailto:${contactEmail}`}>{contactEmail}</Link>
            </Item>
          </List>
        </GridItem>
        <GridItem>
          <List>
            { linksColumn1 ? (
              linksColumn1.map((link) => (
                <Item key={link.title}>
                  <Link to={link.url}>{link.title}</Link>
                </Item>
              ))
            ) : null}
          </List>
        </GridItem>
        <GridItem>
          <List>
            { linksColumn2 ? (
              linksColumn2.map((link) => (
                <Item key={link.title}>
                  <Link to={link.url}>{link.title}</Link>
                </Item>
              ))
            ) : null}
          </List>
        </GridItem>
        <GridItem>
          <Link to="/"><Logo /></Link>
        </GridItem>
      </Grid>
    </Section>
  )
}

export default Footer