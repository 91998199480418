import styled from 'styled-components'
import { breakpoint, SectionAlign } from '../../layout/mixins'
import Button from '../Button'

export const Section = styled(SectionAlign)`
  position: fixed;
  bottom: ${({ theme }) => theme.gutter};
  left: 50%;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.colorAccentDark};
  color: ${({ theme }) => theme.textcolorSecondary};
  padding: ${({ theme }) => theme.gutter};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: ${({ theme }) => theme.zLevel3};

  ${breakpoint.down('max')`
    width: calc(100% - (${({ theme }) => theme.gutter} * 2 ));
  `}
`
export const Text = styled.div`
  max-width: 100%;
  font-size: ${({ theme }) => theme.fontsizeSmall};
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
  `}
  
  ${breakpoint.up('tablet')`
    max-width: 50%;
    padding-bottom: 0;
  `}

  ${breakpoint.up('max')`
    max-width: 33%;
  `}

  p {
    font-family: ${({ theme }) => theme.fontfamilySecondary} !important;
  }
`

export const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.gutterSmall};
`