import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
  StyledButton, StyledArrow, themes, Text
} from './styles'

const Button = (props) => {
  const {
    text, arrow, onClick, theme, type, disabled, className,
  } = props

  return (
    <ThemeProvider theme={themes[theme]}>
      <StyledButton
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={className}
        // eslint-disable-next-line react/destructuring-assignment
        data-cy={props['data-cy']}
      >
        <Text>{text}</Text>
        { arrow ? <StyledArrow /> : null}
      </StyledButton>
    </ThemeProvider>
  )
}

export default Button

Button.propTypes = {
  text: PropTypes.string.isRequired,
  arrow: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  className: PropTypes.string
}

Button.defaultProps = {
  arrow: false,
  theme: 'dark',
  type: 'button',
  disabled: false,
  onClick: () => {},
  className: null
}