import { trimUnits } from '../utils/helpers'

class Theme {
  constructor() {
    this.colorAccent = '#0048B4'
    this.colorAccentDark = '#004587'
    this.colorAccentLight = '#F4F7FA'
    this.colorDanger = '#FF005C'
    this.colorSuccess = '#1AE0A5'

    this.textcolorPrimary = this.colorAccent
    this.textcolorSecondary = '#fff'

    this.backgroundColor = this.colorAccentLight
    this.backgroundColorAlt = '#fff'

    this.gutter = '1rem'
    this.gutterSmall = '0.25rem'
    this.gutterMedium = `${trimUnits(this.gutter) * 1.5}rem`
    this.gutterLarge = `${trimUnits(this.gutter) * 2}rem`
    this.gutterXLarge = `${trimUnits(this.gutter) * 3}rem`
    this.gutterXXLarge = `${trimUnits(this.gutter) * 4}rem`
    this.gutterInput = '0.75rem'

    this.maxWidth = '1700px'
    this.contentWidth = `${trimUnits(this.maxWidth) * 0.53}px`

    this.fontfamilyPrimary = '"Source Serif Pro", sans-serif'
    this.fontfamilySecondary = '"Titillium Web", serif'

    this.fontsizeMobile = '20px'
    this.fontsizeResponsive = 'calc(12px + 0.416vw)'
    this.fontsizeRoot = '20px'

    this.fontsizeSmall = '0.9em'

    this.heading1FontSize = '2.25rem'
    this.heading2FontSize = '1.5rem'
    this.heading3FontSize = '1.2rem'
    this.heading4FontSize = '0.8rem'

    this.lineheightRegular = '1.4em'
    this.lineheightSmall = '1.5em'

    this.zLevel1 = '1'
    this.zLevel2 = '2'
    this.zLevel3 = '3'
    this.zLevel4 = '4'

    this.transitionRegular = '0.4s ease-out'

    this.borderRadius = '5px'

    this.portraitSize = '90px'

    this.iconSize = '60px'

    this.breakpoints = {
      min: 0,
      phone: 668,
      tablet: 768,
      desktop: 1024,
      large: 1280,
      content: trimUnits(this.contentWidth),
      max: trimUnits(this.maxWidth)
    }
  }
}

const theme = new Theme()

export default theme