import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from '../../layout/globalStyles'
import theme from '../../layout/theme'
import CSSReset from '../../layout/reset'
import RTEStyles from '../../layout/rteStyles'
import SEO from '../SEO'
import Header from '../Header'
import Footer from '../Footer'
import CookieNotice from '../CookieNotice'

export default ({ children, seo, progressBar }) => (
  <ThemeProvider theme={theme}>
    <>
      <CSSReset />
      <GlobalStyles />
      <RTEStyles />
      <SEO tags={seo} />
      {/* DOM structure and classes needed for sticky footer */}
      <div className="site-wrapper">
        <div className="content">
          <Header progressBar={progressBar} />
          <main>{children}</main>
        </div>
        <Footer />
      </div>
      <CookieNotice />
    </>
  </ThemeProvider>
)
