import React, { useState, useEffect } from 'react'
import { Bar, Progress } from './styles'

const ProgressBar = () => {
  const [progress, setProgress] = useState(0)

  const displayProgress = () => {
    const computedProgress = Math.min((window.pageYOffset / (document.body.scrollHeight - window.innerHeight)) * 100, 100)
    setProgress(computedProgress)
  }

  useEffect(() => {
    document.addEventListener('scroll', displayProgress, { passive: true })

    return () => {
      document.removeEventListener('scroll', displayProgress)
    }
  }, [])

  return (
    <Bar>
      <Progress style={{ width: `${progress}%` }} />
    </Bar>
  )
}

export default ProgressBar