import React, { useEffect, useState } from 'react'
import {
  Section, Align, Logo, LogoLink, Heading, HeadingLink
} from './styles'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressBar from '../ProgressBar'
import PropTypes from 'prop-types'

const taglineQuery = graphql`
  query HeroQuery {
    data: datoCmsGeneral {
      tagline
    }
  }
`

const Header = ({ progressBar }) => {
  const { data } = useStaticQuery(taglineQuery)
  const [hasScrolled, setHasScrolled] = useState(false)

  const scrollListener = (event) => {
    const threshold = 60
    const position = window.pageYOffset
    if(position > threshold && !hasScrolled) {
      document.body.classList.add('has-scrolled')
      setHasScrolled(true)
    }
    else {
      document.body.classList.remove('has-scrolled')
      setHasScrolled(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollListener, {passive: true})

    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return (
  <>
    <Section data-cy="header" id="header">
      <Align>
        <HeadingLink to="/"><Heading>{data?.tagline}</Heading></HeadingLink>
        <LogoLink to="/"><Logo /></LogoLink>
      </Align>
      { progressBar && hasScrolled ? <ProgressBar /> : null }
    </Section>
  </>
)
  }

export default Header

Header.propTypes = {
  progressBar: PropTypes.bool
}

Header.defaultProps = {
  progressBar: false
}