import styled from 'styled-components'
import {
  SectionAlignStyles, Heading1, Heading1Styles, breakpoint
} from '../../layout/mixins'

export const Section = styled.article`
  ${SectionAlignStyles}
  padding: 0;
  height: 500px;
  color: ${({ theme }) => theme.textcolorSecondary};
  background-color: ${({ theme }) => theme.colorAccentDark};
`


export const ContentGrid = styled.article`
  width: 100%;
  display: grid;
  grid-template-columns: [prefix] 80% [content] 1fr;
  grid-column-gap: ${({ theme }) => theme.gutter};

  padding-left: ${({ theme }) => theme.gutter};
  padding-right: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    padding: 0;
    grid-template-columns: [prefix] 1fr [content] minmax(auto, ${({ theme }) => theme.contentWidth}) [suffix] 1fr;
  `}
`

export const Heading = styled(Heading1)`
  grid-row: 1 / 1;

  &:only-child {
    grid-column: prefix / suffix;
  }

  ${breakpoint.down('phone')`
    grid-column: prefix / content;
  `}
`

export const IndentHeading = styled.strong`
  ${Heading1Styles}

  grid-row: 1 / 1;

  ${breakpoint.down('phone')`
    align-self: end;
    grid-column: content / content;
    
    &:only-child {
      grid-column: prefix / content;
    }
  `}
`

export const Content = styled.article`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: ${({ theme }) => theme.gutterMedium};
`

export const Gradient = styled.div`
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
`