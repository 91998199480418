import React from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  ParagraphSmallStyles,
  ParagraphStyles,
  Heading1Styles,
  Heading2Styles,
  Heading3Styles
} from './mixins'
import theme from './theme'

const RTEStyles = createGlobalStyle`
  .rte {
    h1 {
      ${Heading1Styles}
    }
  
    h2 {
      ${Heading2Styles}
    }
  
    h3 {
      ${Heading3Styles}
    }
  
    h1, h2, h3 {
      margin-top: 1rem;
      margin-bottom: 0.25rem;
      hyphens: auto;
      word-break: break-all;
    }

    p {
      ${ParagraphSmallStyles}
      margin-top: 0;
    }

    p + p {
      margin-top: 1em;
    }

    a {
      text-decoration: underline;
    }
  }
  .rte--large {
    p {
      ${ParagraphStyles}
    }
  }
  .rte--invert {
    h1, h2, h3, h4 {
      color: ${theme.textcolorSecondary};
    }
    p {
      color: ${theme.textcolorSecondary};
    }
    a {
      color: ${theme.textcolorSecondary};
    }
  }
`
export default RTEStyles