import styled from 'styled-components'
import Arrow from '../../layout/icons/arrow-right.svg'
import siteTheme from '../../layout/theme'
import { breakpoint } from '../../layout/mixins'

export const StyledArrow = styled(Arrow)`
  fill: ${({ theme }) => theme.colorAccent};
  width: 23px;
  height: 16px;
  margin-left: ${({ theme }) => theme.gutter};
`

export const StyledButton = styled.button`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 2px solid ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: ${({ theme }) => theme.gutterInput};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;

  &:not([disabled]):hover {
    background: ${({ theme }) => theme.hover.backgroundColor};
    color: ${({ theme }) => theme.hover.color};

    ${StyledArrow} {
      fill: ${({ theme }) => theme.hover.color};
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
export const Text = styled.span`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  line-height: 1;
  display: inline-block;
`

export const themes = {
  light: {
    backgroundColor: 'transparent',
    color: siteTheme.textcolorSecondary,
    hover: {
      backgroundColor: siteTheme.textcolorSecondary,
      color: siteTheme.colorAccent,
    }
  },
  dark: {
    backgroundColor: 'transparent',
    color: siteTheme.colorAccent,
    hover: {
      backgroundColor: siteTheme.colorAccent,
      color: siteTheme.textcolorSecondary,
    }
  }
}