import styled, { css } from 'styled-components'
import styledBreakpoint from '@humblebee/styled-components-breakpoint'
import Tippy from '@tippyjs/react'
import theme from './theme'
import Arrow from './icons/arrow-right.svg'
import 'tippy.js/dist/tippy.css'


export const breakpoint = styledBreakpoint({
  phone: theme.breakpoints.phone,
  tablet: theme.breakpoints.tablet,
  desktop: theme.breakpoints.desktop,
  large: theme.breakpoints.large,
  max: theme.breakpoints.max,
  content: theme.breakpoints.content
})

export const SectionAlignStyles = css`
  width: 100%;
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};

  ${breakpoint.up('phone')`
    padding-left: ${theme.gutterXLarge};
    padding-right: ${theme.gutterXLarge};
  `}
`

export const SectionAlign = styled.section`
  ${SectionAlignStyles}
`

export const ContentAlignStyles = css`
  max-width: ${theme.contentWidth};
  margin: 0 auto;
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};
`

export const ContentAlign = styled.article`
  ${ContentAlignStyles}
`

export const Heading1Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-size: ${theme.heading1FontSize};
  font-weight: normal;
  line-height: 1;
`

export const Heading1 = styled.h1`
  ${Heading1Styles}
`

export const Heading2Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-size: ${theme.heading2FontSize};
  font-weight: normal;
  margin-bottom: 1em;
  line-height: ${theme.lineheightRegular};
`

export const Heading2 = styled.h2`
  ${Heading2Styles}
`

export const Heading3Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-size: ${theme.heading3FontSize};
  line-height: 1em;
  font-weight: 500;
  margin: 0;
`

export const Heading3 = styled.h3`
  ${Heading3Styles}
`

export const Heading4Styles = css`
  font-family: ${theme.fontfamilySecondary};
  font-size: ${theme.heading4FontSize};
  font-weight: 400;
  margin: 0;
  line-height: ${theme.lineheightRegular};
`

export const Heading4 = styled.h4`
  ${Heading4Styles}
`

export const ParagraphStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 1em;
  font-weight: 400;
  line-height: ${theme.lineheightRegular};
`

export const Paragraph = styled.p`
  ${ParagraphStyles}
`

export const ParagraphSmallStyles = css`
  font-family: ${theme.fontfamilyPrimary};
  font-size: 0.9em;
  font-weight: 400;
  line-height: ${theme.lineheightSmall};
`

export const ParagraphLarge = styled.p`
  ${ParagraphSmallStyles}
`
export const StyledTippy = styled(Tippy)`
  font-family: ${theme.fontfamilySecondary};
  font-size: ${theme.fontsizeSmall};
  color: ${theme.colorAccent};
  background: ${theme.backgroundColorAlt};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: ${theme.borderRadius};
`
export const Hidden = styled.div`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
`

const ArrowCSS = css`
  width: 18px;
  height: 15px;
  fill: ${theme.colorAccent};
`

export const ArrowDown = styled(Arrow)`
  ${ArrowCSS}
  transform: rotate(90deg)
`

export const ArrowUp = styled(Arrow)`
  ${ArrowCSS}
  transform: rotate(-90deg)
`


export const InputStyles = css`
  background-color: ${theme.backgroundColorAlt};
  border: 2px solid #EAF0FA;
  border-radius: ${theme.borderRadius};
  color: ${theme.colorAccent};
  padding: ${theme.gutterInput};
  line-height:normal !important;
  font-size: 1rem;

  &::placeholder {
    color: ${theme.colorAccent};
    opacity: 1;
  }

  &:focus {
    border-color: ${theme.colorAccent};
  }

  ${({ required }) => required && `
    &:focus:invalid {
      border-color: ${theme.colorDanger};
      color: ${theme.colorDanger};
      &::placeholder {
        color: ${theme.colorDanger};
      }
    }

    &:valid {
      border-color: ${theme.colorSuccess};
      color: ${theme.colorSuccess};
    }
  `}

  ${({ valid }) => valid && `
    border-color: ${theme.colorSuccess};
    color: ${theme.colorSuccess};
  `}
`

export const DropdownStyles = css`
  font-family: ${theme.fontfamilySecondary};
  width: 100%;
  max-width: 345px;
  cursor: pointer;

  .Dropdown-control {
    ${InputStyles}
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    display: flex;
    justify-content: space-between;
    transition: none;
  }

  ${({ value }) => value && `
    &:not(.is-open)  .Dropdown-control {
      border-color: ${theme.colorSuccess};
      color: ${theme.colorSuccess};
      ${ArrowDown} {
        fill: ${theme.colorSuccess};
      }
    }
  `}

  &.is-open .Dropdown-control {
    border-color: ${theme.colorAccent};
  }

  .Dropdown-menu {
    border: 2px solid ${theme.colorAccent};
    border-top: 0;
    border-bottom-left-radius: ${theme.borderRadius};
    border-bottom-right-radius: ${theme.borderRadius};
    color: ${theme.colorAccent};
    margin-top: -4px;
  }

  .Dropdown-option {
    color: ${theme.colorAccent};
    padding: calc(theme.gutter} / 2) ${theme.gutter};
  }

  .Dropdown-option.is-selected,
  .Dropdown-option:hover {
    background-color: ${theme.backgroundColor};
    color: ${theme.colorAccent};
  }
`
