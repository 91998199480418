import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useCookies } from 'react-cookie'
import { Section, Text, StyledButton } from './styles'
import { isSSR } from '../../utils/helpers'

const env = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

const cookieNoticeQuery = graphql`
  query CookieNoticeQuery {
    data: datoCmsCookieNotice {
      buttonText
      textContent
    }
  }
`
const CookieNotice = () => {
  const { data: { textContent, buttonText } } = useStaticQuery(cookieNoticeQuery)
  const [cookies, setCookie] = useCookies(['bdcookies'])
  const [visible, setVisible] = useState(!isSSR && cookies?.bdcookies !== 'true')
  const date = new Date()
  const expires = new Date(date.setDate(date.getDate() + 30))

  const acceptTerms = () => {
    setCookie('bdcookies', 'true', {
      path: '/', expires, sameSite: true, secure: env !== 'development'
    })
    setVisible(false)
  }

  if (visible) {
    return (
      <Section data-cy="cookie-notice">
        <Text className="rte rte--invert" dangerouslySetInnerHTML={{ __html: textContent }} />
        <StyledButton data-cy="cookie-notice-accept" text={buttonText} theme="light" onClick={acceptTerms} />
      </Section>
    )
  }

  return null
}

export default CookieNotice