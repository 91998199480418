import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Section, Heading, Content, ContentGrid, IndentHeading, Gradient
} from './styles'
import siteTheme from '../../layout/theme'
import BackgroundImage from 'gatsby-background-image'

export const query = graphql`
  fragment HeroImageFragment on DatoCmsFileField {
    fluid(maxWidth: 2048, imgixParams: { fm: "jpg", auto: "compress" }, resizes: [375, 750, 850, 1024, 1700, 2048]) {
      ...GatsbyDatoCmsFluid
    }
  }
`

const Hero = ({
  backgroundImage, title, indentTitle, children
}) => {
  const { max } = siteTheme.breakpoints

  const sizedBackgroundImage = {
    ...backgroundImage?.fluid,
    sizes: `(max-width: ${max}px) 100vw, ${max}px`
  }

  return (
    <Section as={backgroundImage ? BackgroundImage : 'article'} fluid={sizedBackgroundImage}>
      { backgroundImage && <Gradient /> }
      { children || (
      <Content>
        <ContentGrid>
          { indentTitle ? (
            <IndentHeading>{indentTitle}</IndentHeading>
          ) : null}
          { title ? (
            <Heading>{title}</Heading>
          ) : null}
        </ContentGrid>
      </Content>
      )}
    </Section>
  )
}

export default Hero

Hero.propTypes = {
  backgroundImage: PropTypes.oneOfType([
    PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        base64: PropTypes.string.isRequired,
        sizes: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
      }).isRequired
    }),
    PropTypes.bool
  ]).isRequired,
  title: PropTypes.string,
  indentTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  children: PropTypes.node
}

Hero.defaultProps = {
  backgroundImage: false,
  title: null,
  indentTitle: null,
  children: null
}