import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { v4 as uuidv4 } from 'uuid';

const metaQuery = graphql`
  query SEO {
    datoCmsFaviconMetaTags {
      tags
    }
  }
`

const SEO = ({ tags, bodyClass }) => {
  const faviconTags = useStaticQuery(metaQuery)
  return (
    <>
      <Helmet
        title="Bengt Dahlgren"
        bodyAttributes={{
          class: bodyClass
        }}
        htmlAttributes={{
          lang: 'sv'
        }}
      >
        {/* SEO meta */}
        {tags.map((tag) => {
          if (tag.tagName === 'title') {
            return <title key={uuidv4()}>{tag.content}</title>
          }
          if (tag.tagName === 'meta') {
            return <meta key={uuidv4()} {...tag.attributes} />
          }
        })}

        {/* Favicon meta */}
        {faviconTags?.datoCmsFaviconMetaTags?.tags.map((tag) => {
          if (tag.tagName === 'link') {
            return <link key={uuidv4()} {...tag.attributes} />
          }

          if (tag.tagName === 'meta') {
            return <meta key={uuidv4()} {...tag.attributes} />
          }
        })}
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  tags: PropTypes.array,
  bodyClass: PropTypes.string
}

SEO.defaultProps = {
  tags: [],
  bodyClass: ''
}
