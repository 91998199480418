import styled from 'styled-components'

export const Bar = styled.article`
  width: 100%;
  height: 5px;
  position: relative;
  background: ${({ theme }) => theme.backgroundColorAlt};
`

export const Progress = styled.div`
  height: 5px;
  position: absolute;
  top: 0; 
  left: 0;
  background: ${({ theme }) => theme.colorAccent};
  display: block;
  border: 0;
  margin: 0;
  padding: 0;
`